export const environment = {
    production: false,
    clientId:  "222792329477-efsjtnand31jvntl7738ql44bhruqor2.apps.googleusercontent.com",
    fhirEndpoint: {
        baseURL: "https://healthcare.googleapis.com/v1beta1/",
        project: "fhirworlfkow",
        location: "us-central1",
        dataset: "fhirdata",
        fhirStore: "fhirdatastoreid"
    }
};
